import Vue from 'vue'
import App from './App.vue'
// 引入ELEMENT
import ElementUI from 'element-ui'
// import variables from '@/assets/theme/element-variables.scss'
import 'element-ui/lib/theme-chalk/index.css'

Vue.use(ElementUI)

import './assets/iconfont/iconfont.css'

import './assets/manage.css'

// 引入FILTER
import filters from '../src/utils/filter'
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

// 引入全局配置
import config from '../src/utils/config'
Vue.prototype.$config = config.data



Vue.config.productionTip = false

import router from './router'
import store from './store'
router.beforeEach((to, from, next) => {
  document.title = (to.meta.title ? to.meta.title + '-' : '') + '无人机取证预约报名系统'
  next()
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
// 引入cookie-js
import Cookie from 'js-cookie'

import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
	mode: 'history',
	routes: [{
		path: '/',
		component: resolve => require(['@/views/index'], resolve)
	}, {
		path: '/manage/',
		meta: {
			requireAuth: true
		},
		component: resolve => require(['@/views/manage/index'], resolve),
		children: [{
				path: '/manage/',
				meta: {
					title: '管理后台',
					requireAuth: true
				},
				component: resolve => require(['@/views/manage/home'], resolve)
			}, {
				path: '/manage/setting/index',
				meta: {
					title: '系统设置',
					requireAuth: true
				},
				component: resolve => require(['@/views/manage/setting/index'], resolve)
			}, {
				path: '/manage/admin/lists',
				meta: {
					title: '管理人员',
					requireAuth: true
				},
				component: resolve => require(['@/views/manage/admin/lists'], resolve)
			}, {
				path: '/manage/admin/group',
				meta: {
					title: '管理角色',
					requireAuth: true
				},
				component: resolve => require(['@/views/manage/admin/group'], resolve)
			}, {
				path: '/manage/admin/menu',
				meta: {
					title: '管理权限',
					requireAuth: true
				},
				component: resolve => require(['@/views/manage/admin/menu'], resolve)
			}, {
				path: '/manage/advert/lists',
				meta: {
					title: '广告管理',
					requireAuth: true
				},
				component: resolve => require(['@/views/manage/advert/lists'], resolve)
			}, {
				path: '/manage/user/lists',
				meta: {
					title: '用户管理',
					requireAuth: true
				},
				component: resolve => require(['@/views/manage/user/lists'], resolve)
			}, {
				path: '/manage/coupon/lists',
				meta: {
					title: '优惠券',
					requireAuth: true
				},
				component: resolve => require(['@/views/manage/coupon/lists'], resolve)
			}, {
				path: '/manage/coupon/record',
				meta: {
					title: '领券记录',
					requireAuth: true
				},
				component: resolve => require(['@/views/manage/coupon/record'], resolve)
			}, {
				path: '/manage/coupon/add',
				meta: {
					title: '发券设置',
					requireAuth: true
				},
				component: resolve => require(['@/views/manage/coupon/add'], resolve)
			}, {
				path: '/manage/coupon/add/:id',
				meta: {
					title: '发券设置',
					requireAuth: true
				},
				component: resolve => require(['@/views/manage/coupon/add'], resolve)
			},
			{
				path: '/manage/question/class',
				meta: {
					title: '题库分类',
					requireAuth: true
				},
				component: resolve => require(['@/views/manage/question/class'], resolve)
			},
			{
				path: '/manage/question/lists',
				meta: {
					title: '题库管理',
					requireAuth: true
				},
				component: resolve => require(['@/views/manage/question/lists'], resolve)
			},
			{
				path: '/manage/product/lists',
				meta: {
					title: '课程',
					requireAuth: true
				},
				component: resolve => require(['@/views/manage/product/lists'], resolve)
			},
			{
				path: '/manage/product/class',
				meta: {
					title: '证书类型',
					requireAuth: true
				},
				component: resolve => require(['@/views/manage/product/class'], resolve)
			},
			{
				path: '/manage/train/exam',
				meta: {
					title: '模拟考试',
					requireAuth: true
				},
				component: resolve => require(['@/views/manage/train/exam'], resolve)
			},
			{
				path: '/manage/train/lists',
				meta: {
					title: '练习管理',
					requireAuth: true
				},
				component: resolve => require(['@/views/manage/train/lists'], resolve)
			},
			{
				path: '/manage/deal/lists',
				meta: {
					title: '预约管理',
					requireAuth: true
				},
				component: resolve => require(['@/views/manage/deal/lists'], resolve)
			},
			{
				path: '/manage/article/lists',
				meta: {
					title: '咨询管理',
					requireAuth: true
				},
				component: resolve => require(['@/views/manage/article/lists'], resolve)
			},
			{
				path: '/manage/article/class',
				meta: {
					title: '咨询分类',
					requireAuth: true
				},
				component: resolve => require(['@/views/manage/article/class'], resolve)
			},
			{
				path: '/manage/article/add',
				meta: {
					title: '咨询发布',
					requireAuth: true
				},
				component: resolve => require(['@/views/manage/article/add'], resolve)
			},
			{
				path: '/manage/article/add/:id',
				meta: {
					title: '咨询发布',
					requireAuth: true
				},
				component: resolve => require(['@/views/manage/article/add'], resolve)
			},
			{
				path: '/manage/certificate/lists',
				meta: {
					title: '证书查询',
					requireAuth: true
				},
				component: resolve => require(['@/views/manage/certificate/lists'], resolve)
			}
		]
	}]
})

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

// 导航守卫
router.beforeEach((to, from, next) => {
	if (to.meta.requireAuth == true) { // 判断该路由是否需要登录权限
		let admin = Cookie.get('admin')
		if (admin === undefined || admin === 'e30=' || !admin) { // cookie是否存在
			next({
				path: '/manage/' // 跳转到登录界面
			})
		} else {
			next()
		}
	} else {
		next()
	}
})

export default router